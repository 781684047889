import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
     

     <path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m5348 1598 l2 42 154 0 c218 0 312 -24 413 -106 107 -88 172 -245 160
-394 -8 -97 -16 -128 -38 -133 -10 -3 -19 -11 -19 -19 0 -10 2 -10 10 2 5 8
12 12 15 9 7 -8 -16 -59 -27 -59 -4 0 -8 -7 -8 -16 0 -22 -62 -96 -76 -90 -6
2 -11 -2 -10 -10 0 -8 -12 -22 -29 -32 -16 -9 -32 -19 -35 -22 -8 -9 -81 -38
-125 -51 -22 -6 -140 -13 -262 -16 -257 -6 -234 -16 -252 112 -6 44 -20 132
-31 195 l-20 114 42 85 43 84 8 -34 c10 -39 16 -76 46 -274 12 -77 24 -146 26
-153 4 -10 45 -12 178 -12 144 1 184 5 235 22 112 36 199 153 209 282 7 78 -4
172 -21 189 -7 7 -16 24 -19 39 -4 15 -25 46 -47 69 -22 23 -37 46 -34 50 3 5
-2 6 -11 3 -9 -4 -24 1 -33 9 -30 27 -101 37 -283 37 l-176 0 -6 40 c-3 22 -2
50 1 63 5 17 8 12 13 -23 l6 -45 1 43z m-138 -6 c0 -16 5 -54 11 -85 8 -40 9
-61 1 -69 -6 -7 -59 -107 -118 -223 -59 -115 -142 -277 -184 -359 -42 -81 -80
-149 -84 -150 -5 0 -47 76 -95 169 -76 147 -123 237 -168 320 -6 11 -21 39
-32 62 -11 23 -24 50 -30 60 -5 10 -19 37 -30 60 -11 23 -25 50 -32 61 -9 15
-9 34 2 91 8 40 18 72 23 73 9 0 34 -40 83 -134 8 -16 12 -38 8 -50 -5 -17 -4
-19 3 -9 8 11 16 2 37 -40 67 -133 169 -328 192 -366 17 -29 22 -49 18 -65 -6
-19 -3 -18 23 10 21 23 32 29 36 20 3 -7 3 -1 0 13 -3 15 -1 30 5 33 5 4 22
32 36 64 24 50 28 54 36 37 8 -17 9 -16 4 5 -7 35 146 339 157 313 5 -11 6 -6
2 14 -3 17 -1 34 4 37 6 3 25 35 43 71 35 71 49 81 49 37z m-764 -368 c16 -30
34 -65 41 -77 8 -17 8 -43 -4 -112 -8 -49 -22 -141 -32 -202 -9 -62 -20 -115
-24 -119 -3 -3 -31 -8 -61 -11 l-56 -5 6 49 c3 26 9 57 14 68 4 11 13 56 19
100 6 44 20 131 31 194 11 62 20 126 20 142 0 44 14 35 46 -27z m-1333 -1214
c91 -195 183 -395 206 -445 23 -49 48 -102 57 -118 8 -15 13 -30 11 -35 -3 -4
-37 -6 -76 -4 l-71 4 -21 46 c-59 129 -225 495 -251 552 -22 48 -32 61 -38 50
-4 -8 -56 -121 -115 -250 -59 -129 -122 -267 -141 -307 -19 -40 -34 -77 -34
-83 0 -11 1 -11 -80 -11 -33 0 -64 0 -69 0 -5 1 4 29 20 62 17 34 52 109 78
168 27 58 68 147 91 196 59 124 115 246 190 408 35 76 67 134 71 130 4 -4 81
-168 172 -363z m2477 60 c278 -598 302 -651 297 -657 -3 -3 -35 -6 -71 -7
l-66 -1 -21 45 c-12 25 -54 117 -94 205 -159 353 -188 414 -195 410 -4 -3 -27
-48 -50 -101 -23 -53 -61 -138 -85 -188 -23 -50 -68 -149 -100 -221 -32 -71
-62 -134 -66 -139 -7 -8 -139 -9 -147 -2 -1 2 23 57 54 122 50 106 109 231
221 474 19 41 51 109 70 150 20 41 51 108 69 149 19 41 36 72 39 70 2 -3 67
-142 145 -309z m1693 168 c36 -79 138 -297 226 -486 l159 -343 -76 3 -76 3
-106 231 c-103 226 -128 281 -170 377 -12 26 -23 47 -26 47 -3 0 -21 -35 -40
-77 -19 -43 -39 -87 -44 -98 -32 -66 -154 -337 -168 -370 -8 -22 -25 -58 -36
-79 l-21 -40 -70 3 c-38 1 -70 3 -71 4 -1 2 117 260 202 442 50 106 180 385
220 473 14 28 26 52 28 52 2 0 33 -64 69 -142z m-5583 -233 c91 -179 170 -325
175 -325 6 0 59 97 119 215 163 320 247 477 253 471 6 -6 33 -153 33 -185 0
-13 -68 -155 -152 -315 -227 -435 -244 -467 -252 -469 -7 -2 -32 43 -206 378
-40 77 -102 196 -138 265 l-65 125 12 80 c7 44 15 91 18 104 l6 24 16 -22 c9
-12 90 -168 181 -346z m1960 -118 c0 -261 -2 -476 -4 -478 -2 -2 -34 -2 -70
-1 l-66 4 0 474 0 474 70 0 70 0 0 -473z m722 269 l3 -205 57 67 c32 37 70 81
85 99 16 18 69 79 118 137 l90 105 94 0 94 1 -24 -27 c-13 -15 -26 -30 -29
-33 -35 -33 -360 -400 -360 -407 0 -4 19 -28 43 -54 23 -25 62 -67 87 -95 25
-27 88 -97 141 -154 53 -58 111 -122 129 -142 l32 -38 -94 0 c-85 0 -95 2
-112 23 -21 24 -193 212 -271 295 -27 30 -54 60 -58 68 -22 38 -27 2 -27 -186
l0 -200 -70 0 -70 0 0 475 0 475 70 0 70 0 2 -204z m2053 190 c96 -25 163 -76
201 -152 29 -60 27 -222 -4 -285 -29 -59 -86 -117 -139 -141 -24 -10 -43 -21
-43 -25 0 -6 45 -71 151 -218 36 -49 69 -96 73 -102 5 -10 -11 -13 -72 -13
l-80 0 -59 81 c-32 45 -84 117 -115 160 l-58 79 -75 0 -75 0 0 -160 0 -160
-70 0 -70 0 0 229 0 229 203 4 c226 3 249 9 294 78 33 47 33 153 0 201 -39 58
-60 63 -287 69 l-205 5 -3 68 -3 67 193 0 c129 0 210 -5 243 -14z m1775 -51
l0 -65 -317 2 -318 3 -3 63 -3 62 321 0 320 0 0 -65z m250 -248 c0 -354 5
-388 65 -450 34 -35 110 -67 160 -67 45 0 119 30 151 61 19 18 43 57 54 86 18
50 20 78 20 368 l0 315 70 0 70 0 0 -279 c0 -154 -5 -314 -11 -357 -19 -139
-82 -239 -183 -292 -47 -25 -60 -27 -171 -27 -102 0 -127 3 -167 22 -93 43
-160 137 -187 261 -7 33 -11 170 -11 362 l0 310 70 0 70 0 0 -313z m-502 -268
l-3 -371 -62 -2 c-44 -1 -64 3 -68 12 -8 20 -6 718 2 725 3 4 35 7 70 7 l63 0
-2 -371z m-6448 145 l42 -81 -26 -159 c-14 -87 -28 -183 -32 -212 -4 -29 -11
-55 -16 -58 -11 -7 -127 -9 -133 -2 -3 2 18 137 45 299 27 162 50 301 50 309
0 24 23 -7 70 -96z m844 -181 c25 -159 49 -298 51 -310 5 -22 3 -23 -65 -23
-64 0 -70 2 -75 23 -2 12 -19 110 -36 217 l-31 194 48 93 c27 51 52 93 55 93
3 0 27 -129 53 -287z m1427 -784 c165 -77 234 -239 180 -418 -16 -54 -31 -80
-66 -115 -90 -92 -193 -120 -425 -114 l-125 3 -3 338 -2 339 192 -4 c184 -3
196 -4 249 -29z m1037 -32 c17 -35 59 -128 95 -208 36 -79 83 -182 105 -229
79 -169 82 -180 39 -180 -33 0 -40 8 -78 95 l-36 80 -190 3 -190 2 -41 -90
c-38 -84 -43 -90 -71 -90 -16 0 -32 3 -34 8 -4 6 57 146 185 422 19 41 50 109
68 150 44 97 46 100 86 100 30 0 35 -4 62 -63z m1182 33 l0 -30 -120 0 -120 0
0 -311 0 -310 -32 3 -33 3 -5 305 -5 305 -117 3 -118 3 0 29 0 30 275 0 275 0
0 -30z m827 -160 c46 -102 113 -247 149 -324 35 -76 64 -144 64 -152 0 -8 -10
-14 -24 -14 -32 0 -56 10 -56 22 0 6 -16 44 -35 84 l-34 74 -188 0 -188 0 -42
-91 c-42 -89 -43 -90 -75 -87 -18 2 -32 8 -31 13 2 10 275 612 295 648 7 13
19 17 46 15 l36 -3 83 -185z m-3787 -145 l0 -25 -810 0 -810 0 0 25 0 25 810
0 810 0 0 -25z m5930 0 l0 -25 -809 0 c-476 0 -812 4 -815 9 -4 5 -3 16 0 25
6 14 85 16 815 16 l809 0 0 -25z"/>
<path d="M5408 6943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5493 6943 c32 -2 81 -2 110 0 29 2 3 3 -58 3 -60 0 -84 -1 -52 -3z"/>
<path d="M5887 6919 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M6020 6730 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5292 6345 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5261 6304 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5917 6293 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M5300 6220 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5418 6203 c34 -2 90 -2 125 0 34 2 6 3 -63 3 -69 0 -97 -1 -62 -3z"/>
<path d="M5668 6203 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M5718 6153 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4506 6897 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M4460 6859 c7 -11 14 -19 16 -16 7 7 -7 37 -17 37 -6 0 -5 -9 1 -21z"/>
<path d="M5075 6750 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5140 6750 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4392 6490 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4373 6395 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z"/>
<path d="M4352 6270 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4381 6254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3420 4056 l0 -276 126 0 c149 0 193 10 256 56 123 89 142 279 39
396 -64 72 -118 90 -283 96 l-138 5 0 -277z"/>
<path d="M4676 4142 c-38 -86 -71 -165 -74 -174 -4 -17 8 -18 146 -18 83 0
153 4 156 8 2 4 -26 75 -63 157 -37 83 -70 158 -74 168 -3 9 -10 17 -14 17 -4
0 -39 -71 -77 -158z"/>
<path d="M6696 4292 c-12 -20 -146 -325 -146 -333 0 -11 297 -12 304 -1 4 7
-133 324 -146 337 -3 4 -9 2 -12 -3z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
